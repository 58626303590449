<template>
  <v-card class="d-flex flex-wrap mb-5 pa-5" outlined>
    <template v-for="(item, idx) in items">
      <v-col cols="6" md="3" :key="idx" v-if="item && item.metric">
        <MetricItem :metric="item" />
      </v-col>
    </template>
  </v-card>
</template>

<script>
export default {
  name: "PromoOverviewMetrics",
  components: {
    MetricItem: () => import("@/components/atoms/MetricItem"),
  },
  computed: {
    items() {
      return this.$store.state.results.promo_overview_metrics;
    },
  },
};
</script>

<style scoped></style>
